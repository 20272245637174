<template>
  <Popper
    :content="copiedText"
    placement="right"
    :show="showPopper"
    class="text-break"
  >
    <div class="d-flex align-items-center" @click="copyToClipboard(text)">
      <slot></slot>
      <i class="ni ni-single-copy-04 text-sm ms-3"></i>
    </div>
  </Popper>
</template>

<script>
import Popper from "vue3-popper";

export default {
  name: "PopperCopyText",
  components: {
    Popper,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    copiedText: {
      type: String,
      default: "Copied!",
    },
  },
  data: () => {
    return {
      showPopper: false,
    };
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.showPopper = true;
      setTimeout(() => {
        this.showPopper = false;
      }, 3000);
    },
  },
};
</script>

<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
